import React from 'react'
import ListBox from '@/components/ListBox'
import MainHeading from '@/components/MainHeading'

interface AwardHeader {
  children: React.ReactNode
  heading?: string
  aside?: any
}

const AwardHeader = ({ children, heading, aside }: AwardHeader) => {
  return (
    <div className="">
      <div className={`max-w-content w-full mx-auto px-4 sm:px-10 2xl:px-2 grid grid-cols-12 sm:gap-x-10`}>
        <div className="col-span-12 mb-2">
          <MainHeading>{heading}</MainHeading>
        </div>
        <div className="col-span-12 lg:col-span-7">{children}</div>
        <aside className="col-start-9 col-end-13 sidebar hidden lg:block mb-10">
          <ListBox heading={'Palkinnot ja palkitut'} list={aside} />
        </aside>
      </div>
    </div>
  )
}

export default AwardHeader
