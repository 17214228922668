import React from 'react'
import DefaultLink from '@/components/DefaultLink'

interface ListItem {
  name: string
  uri: string
}

interface ListBoxData {
  heading?: string
  list?: Array<ListItem>
}

const ListBox = ({ heading, list }: ListBoxData) => {
  return (
    <div className="bg-lightlatte py-8 px-10 ml-6 mt-10">
      {heading && <p className="font-bold text-2xl">{heading}</p>}
      <ul className="list-disc ml-5 list-outside mt-2">
        {list &&
          list.map((item: any, index: number) => {
            return (
              <li key={index} className="text-blue font-bold">
                <DefaultLink className="hover:text-hovbrown focus:text-hovbrown" to={item.uri}>
                  {item.name}
                </DefaultLink>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default ListBox
