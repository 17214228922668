import React from 'react'
import { Link } from 'gatsby'
// import { DefaultTFuncReturn } from 'i18next'

interface Data {
  children: any //React.ReactNode | DefaultTFuncReturn
  to: string
  className?: string
  activeClassName?: string
  partiallyActive?: boolean
}

const RoundedLink = ({ children, to, className = '', activeClassName, partiallyActive }: Data) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <Link
        to={to}
        className={`${className} tracking-full px-6 pt-2 pb-1 border-solid inline-block border-3 rounded-3xl uppercase text-center font-bold`}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
      >
        {children}
      </Link>
    )
  }
  return (
    <a
      href={to}
      className={
        className +
        ' tracking-full px-6 pt-2 pb-1 border-solid inline-block border-3 rounded-3xl uppercase text-center font-bold'
      }
    >
      {children}
    </a>
  )
}
export default RoundedLink
