import React from 'react'
import { Link } from 'gatsby'
import Moment from 'react-moment'

interface PostListItemData {
  date?: string
  heading: string
  content: string
  uri: string
}

const PostListItem = ({ date, heading, content, uri }: PostListItemData) => {
  return (
    <Link className="text-gray block my-6 group transition-all" to={uri}>
      {date && (
        <p className="mb-2">
          <Moment format="DD.MM.YYYY" date={date} />
        </p>
      )}
      <h3 className="text-blue transition-colors duration-300 mb-2 group-hover:text-hovbrown group-focus:text-hovbrown">
        {heading}
      </h3>
      <p>{content}</p>
    </Link>
  )
}

export default PostListItem
