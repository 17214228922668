import React, { useState } from 'react'
import PostListItem from '@/components/PostListItem'
import moment from 'moment'
import Arrow from '@/assets/Arrow'

interface Posts {
  id: string
  title: string
  date: string
  uri: string
  featuredImage: any
  gwAwardShortSummary: string
  gwAwardDate: string
}

interface PaginationData {
  posts: Array<Posts>
}
const Pagination = ({ posts }: PaginationData) => {
  // Settings
  const postPerPage = 6
  const postlength = posts?.length || 0
  const [pageNum, setPageNum] = useState(1)
  // Paginate posts
  const pagedPosts = posts?.slice((pageNum - 1) * postPerPage, pageNum * postPerPage) || []
  // Count pages
  const count = Math.ceil(postlength / postPerPage)

  return (
    <div className="pagination">
      <div>
        {pagedPosts.map(item => {
          const awardYear = moment(item.gwAwardDate).format('YYYY')
          return (
            <PostListItem
              key={item.uri}
              heading={`${awardYear ? awardYear + ' – ' : null} ${item.title}`}
              content={item.gwAwardShortSummary}
              uri={item.uri}
            />
          )
        })}
      </div>
      <div className="mt-10">
        {[...Array(count)].map((e, i) => {
          // Take a breath...
          if (
            (i > pageNum - 2 && pageNum + 3 > i + 1) ||
            (i < pageNum + 2 && pageNum - 3 < i + 1) ||
            (pageNum === 1 && 6 > i + 1) ||
            (pageNum === 2 && 6 > i + 1) ||
            (pageNum === count && i + 1 > pageNum - 5) ||
            (pageNum === count - 1 && i > pageNum - 5)
          ) {
            return (
              <button
                className={`m-1 p-1 font-bold border-b-2 hover:border-yellow focus:border-yellow hover:text-yellow focus:text-yellow ${
                  pageNum == i + 1 ? 'text-yellow border-yellow' : 'border-white'
                }`}
                onClick={() => setPageNum(i + 1)}
                key={i}
              >
                {i + 1}
              </button>
            )
          }
        })}
        {pageNum !== count ? (
          <button
            onClick={() => setPageNum(pageNum + 1)}
            className={`m-1 p-1 font-bold hover:text-yellow focus:text-yellow pagination-icon`}
          >
            <Arrow />
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default Pagination
