import React from 'react'
import Image from '@/components/Image'

type ImageLargeData = {
  image: any
}

// Chekc if image prop is set and if it's a string
const ImageLarge = ({ image }: ImageLargeData) => {
  return (
    <div className="image-block relative my-20 mb-30">
      <Image alt="Large image" image={image} className="inline-full-width w-full h-auto relative z-20" />
      <div className="bg-latte h-full w-full absolute top-5 -left-10 sm:top-10 sm:-left-10"></div>
    </div>
  )
}
export default ImageLarge
