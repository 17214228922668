import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import TextAndImage from '@/components/TextAndImage'
import Gutenberg from '@/components/Gutenberg'
import AwardHeader from '@/components/AwardHeader'
import Moment from 'react-moment'
import DefaultLink from '@/components/DefaultLink'
import ImageLarge from '@/components/Image/ImageLarge'
import Seo from '@/components/Seo'
import ContainerSidebar from '@/components/Layout/ContainerSidebar'
import Pagination from '@/components/Pagination'
import Image from '@/components/Image/'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
interface DefaultAwardData {
  data: {
    form: any
    award: {
      category: any
      title: string
      description: string
      language: {
        slug: string
      }
      featuredImage: any
      uri: string
      seo: seotype
      translations: []
      gwAwardDate: string
      gwAwardForceMultipleRecipient: any
      gwAwardImage: any
      gwAwardLongSummary: string
      gwAwardPrice: string
      gwAwardRecipient: any
      gwAwardShortSummary: string
      content: any
    }
    mainMenu: any
    wp: any
    awards: any
    categories: {
      nodes: []
    }
  }
}

const SingleAwardTemplate = ({ data }: DefaultAwardData) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(award?.language.slug)
  }, [])

  const { award } = data
  const { form } = data
  const { awards } = data
  const secondLatestAward = awards?.nodes[1]
  const { categories } = data
  const { mainMenu } = data
  const { wp } = data
  const langData = { languages: wp.languages, currentLang: award.language.slug, translations: award.translations }
  return (
    <Layout featuredImage={award.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {award.seo && (
        <Seo
          title={award?.seo?.title}
          description={award?.seo?.metaDesc}
          keywords={award?.seo?.metaKeyword}
          pathname={award?.uri}
          lang={award?.language?.slug}
          image={award?.featuredImage?.node?.localFile?.url}
          twitterTitle={award?.seo?.twitterTitle}
          twitterDescription={award?.seo?.twitterDescription}
          opengraphTitle={award?.seo?.opengraphTitle}
          opengraphDescription={award?.seo?.opengraphDescription}
          opengraphImage={award?.seo?.opengraphImage?.localFile?.url}
          twitterImage={award?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <AwardHeader heading={award.title} aside={categories?.nodes}>
        {award.category.nodes &&
          award.category.nodes.map((cat: any) => {
            return (
              <DefaultLink className="text-2xl text-blue font-bold" key={cat.slug} to={cat.uri}>
                {cat.name}
              </DefaultLink>
            )
          })}
        <div className="mt-10">
          {award.gwAwardDate && (
            <p className="text-2xl mb-2 font-bold">
              <Trans>Myönnetty</Trans>: <Moment format="DD.MM.YYYY" date={award.gwAwardDate} />
            </p>
          )}
          {award.gwAwardPrice && (
            <p className="text-2xl font-bold">
              <Trans>Palkinnon arvo</Trans>: {award.gwAwardPrice} €
            </p>
          )}

          {(award.gwAwardForceMultipleRecipient || award.gwAwardRecipient.length > 1) && award.gwAwardRecipient && (
            <div className="mt-10">
              {award.gwAwardRecipient.map((item: any, index: number) => {
                return (
                  <p className="text-xl text-blue font-bold mb-4" key={index}>
                    {item.name}
                  </p>
                )
              })}
            </div>
          )}
        </div>
      </AwardHeader>
      <Container>
        {award.gwAwardForceMultipleRecipient || award.gwAwardRecipient.length > 1 ? (
          <ImageLarge image={award.gwAwardImage} />
        ) : (
          <TextAndImage
            contentUpper={award.gwAwardShortSummary}
            img={award.gwAwardImage}
            imgSide={'left'}
            imgBg={'bg-latte'}
            className="mb-0 mt-12 md:mt-20 md:mb-20"
          >
            <p>{award.gwAwardLongSummary}</p>
          </TextAndImage>
        )}
      </Container>
      <Container>
        <Gutenberg>{award.content}</Gutenberg>
      </Container>
      <ContainerSidebar
        className="my-20"
        sidebar={
          secondLatestAward && (
            <>
              <Image alt="kuva" image={secondLatestAward.gwAwardImage} />{' '}
              <p className="mt-4 leading-normal text-sm">{secondLatestAward.gwAwardShortSummary}</p>
            </>
          )
        }
      >
        <h2 className="text-3xl">
          <Trans>Palkittuja</Trans>
        </h2>
        <Pagination posts={awards?.nodes} />
      </ContainerSidebar>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AwardById(
    $id: String!
    $postlang: [String]
    $menu: [WpMenuLocationEnum]
    $catslug: [String]
    $translang: String
    $formNum: Int
  ) {
    award: wpGwAward(id: { eq: $id }) {
      id
      title
      uri
      seo {
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            url
          }
        }
        twitterImage {
          localFile {
            url
          }
        }
      }
      content
      ...featuredHeroImageAward
      ...langdataAward
      gwAwardDate
      gwAwardForceMultipleRecipient
      gwAwardImage
      gwAwardLongSummary
      gwAwardPrice
      gwAwardRecipient {
        name
      }
      gwAwardShortSummary
      category: gwAwardsCustomTaxonomyPlural {
        nodes {
          name
          uri
          slug
        }
      }
    }
    awards: allWpGwAward(
      sort: { fields: gwAwardDate, order: DESC }
      filter: { terms: { nodes: { elemMatch: { slug: { in: $catslug } } } }, language: { slug: { in: $postlang } } }
    ) {
      ...allAwards
    }
    categories: allWpGwAwardsCustomTaxonomySingle(
      filter: { language: { slug: { in: $postlang } } }
      sort: { fields: gwAwardCatOrder, order: ASC }
    ) {
      nodes {
        uri
        id
        name
      }
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default SingleAwardTemplate
