import React from 'react'
import RoundedLink from '@/components/RoundedLink'
import Image from '@/components/Image'

interface TextAndImageData {
  link?: string
  alt?: string
  className?: string
  linkText?: string
  contentUpper?: any
  content?: any
  img?: any
  imgBg?: string
  imgSide?: string
  children?: any
  heading?: string
  isIndent?: boolean
  useh1?: boolean
}

const TextAndImage = ({
  link,
  alt,
  className = '',
  linkText,
  heading,
  contentUpper,
  children,
  img,
  imgBg,
  imgSide,
  isIndent,
  useh1 = false,
}: TextAndImageData) => {
  if (imgSide === 'left') {
    return (
      <div className={`${className} text-and-image w-full grid grid-cols-9`}>
        {img && (
          <div className="col-span-9 sm:col-span-4 flex sm:block justify-center">
            <div className="w-full xs:w-4/5 relative mb-14 sm:mb-0 mr-0 md:mr-10 lg:mr-20">
              <Image image={img} className={'h-auto relative z-20 w-full'} alt={alt ? alt : 'Vaihtoehtoinen teksti'} />
              <div className={`${imgBg} h-full w-full absolute top-5 -left-5 sm:top-10 sm:-left-10`}></div>
            </div>
          </div>
        )}

        <div className="col-span-9 sm:col-span-5 flex flex-col justify-center items-start break-words sm:ml-5">
          {heading &&
            (useh1 ? (
              <h1 className="mb-4 w-full text-4xl md:text-5xl break-words uppercase">{heading}</h1>
            ) : (
              <h2 className="uppercase w-full mb-5 md:mb-10 break-words text-4xl md:text-5xl">{heading}</h2>
            ))}
          <div className={`${heading && 'md:pl-20'}`}>
            {contentUpper && (
              <div className={`mb-5 font-bold text-2xl`}>
                <p>{contentUpper}</p>
              </div>
            )}
            {children && <div className={` mb-5`}>{children}</div>}

            {link && (
              <RoundedLink
                className="border-black text-black hover:text-white hover:bg-black focus:text-white focus:bg-black"
                to={link}
              >
                {linkText}
              </RoundedLink>
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`${className} text-and-image w-full grid grid-cols-9`}>
        <div className="col-span-9 sm:col-span-5 flex flex-col justify-center items-start break-words sm:mr-10 md:mr-20">
          {heading &&
            (useh1 ? (
              <h1 className="mb-4 w-full text-4xl md:text-5xl break-words uppercase">{heading}</h1>
            ) : (
              <h2 className="uppercase w-full mb-5 md:mb-10 break-words text-4xl md:text-5xl">{heading}</h2>
            ))}
          <div className={`${isIndent && 'sm:pl-10 md:pl-10 lg:pl-20'} `}>
            {contentUpper && (
              <div className="mb-5 font-bold text-2xl">
                <p>{contentUpper}</p>
              </div>
            )}
            {children && <div className="mb-7">{children}</div>}

            {link && (
              <RoundedLink
                className="border-black text-black hover:text-white hover:bg-black focus:text-white focus:bg-black"
                to={link}
              >
                {linkText}
              </RoundedLink>
            )}
          </div>
        </div>
        {img && (
          <div className="w-full col-span-9 sm:col-span-4 mt-16 sm:mt-0 flex sm:block justify-center">
            <div className="relative w-full xs:w-4/5 ml-0 md:ml-10 lg:ml-20">
              <Image image={img} className={'h-auto relative z-20 w-full'} alt={alt ? alt : 'Vaihtoehtoinen teksti'} />
              <div className={`${imgBg} h-full w-full absolute -top-5 -right-5 sm:-top-10 sm:-right-10`}></div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default TextAndImage
